<template>
  <b-container class="bv-example-row mb-3" fluid
  >
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner">
      <b-card id="printMe">
        <b-row>
          <b-col cols="4">
            <h4 v-show="!filtre">Yılı Ayı Konaklama ve Tarife Raporu</h4>
            <h4 v-show="filtre">
              {{ year }} Yılı {{ month }} Ayı Konaklama ve Tarife Raporu
            </h4>
          </b-col>
          <b-col
              cols="8"
              style="justify-content: end; display: flex"
              class="unPrint"
          >
            <v-select v-model="year" :options="years"/>
            <v-select v-model="month" :options="months" class="ml-50 mr-1"/>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                v-print="'#printMe'"
            >
              <feather-icon icon="PrinterIcon" class="mr-50"/>
              Listeyi Yazdır
            </b-button>
          </b-col>
        </b-row>
        <hr/>
        <div v-for="(tarife, index) in tarifeGet" :key="index">
          <strong>{{ tarife.tarifeAdi }}</strong>
          <table class="table table-borderless mb-2 mt-1">
            <thead>
            <tr>
              <th
                  scope="col"
                  v-for="(item, index) in aylikRaporReturn.toplamGun == '28'
                    ? 28
                    : '' || aylikRaporReturn.toplamGun == '29'
                    ? 29
                    : '' || aylikRaporReturn.toplamGun == '30'
                    ? 30
                    : '' || aylikRaporReturn.toplamGun == '31'
                    ? 31
                    : '' || aylikRaporReturn.toplamGun == '32'
                    ? 32
                    : ''"
                  :key="index"
              >
                {{ item }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td
                  v-for="(item, index) in aylikRaporReturn.toplamGun == '28'
                    ? 28
                    : '' || aylikRaporReturn.toplamGun == '29'
                    ? 29
                    : '' || aylikRaporReturn.toplamGun == '30'
                    ? 30
                    : '' || aylikRaporReturn.toplamGun == '31'
                    ? 31
                    : '' || aylikRaporReturn.toplamGun == '32'
                    ? 32
                    : ''"
                  :key="index"
              >
                <router-link
                    :to="{
                      path: '/raporGunDetay/',
                      query: {
                        tarih: goRaporGun(index + 1),
                        tarife: tarife.tarifeID,
                      },
                    }"
                    tag="a"
                >{{ aylikRaporReturn[item][tarife.tarifeID] }}
                </router-link
                >
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <strong>Toplam Konaklama</strong>
          <table class="table table-borderless mb-2 mt-1">
            <thead>
            <tr>
              <th
                  scope="col"
                  v-for="(item, index) in aylikRaporReturn.toplamGun == '28'
                    ? 28
                    : '' || aylikRaporReturn.toplamGun == '29'
                    ? 29
                    : '' || aylikRaporReturn.toplamGun == '30'
                    ? 30
                    : '' || aylikRaporReturn.toplamGun == '31'
                    ? 31
                    : '' || aylikRaporReturn.toplamGun == '32'
                    ? 32
                    : ''"
                  :key="index"
              >
                {{ item }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td
                  v-for="(item, index) in aylikRaporReturn.toplamGun == '28'
                    ? 28
                    : '' || aylikRaporReturn.toplamGun == '29'
                    ? 29
                    : '' || aylikRaporReturn.toplamGun == '30'
                    ? 30
                    : '' || aylikRaporReturn.toplamGun == '31'
                    ? 31
                    : '' || aylikRaporReturn.toplamGun == '32'
                    ? 32
                    : ''"
                  :key="index"
              >
                <router-link
                    :to="{
                      path: '/raporGunDetay/',
                      query: { tarih: goRaporGun(index + 1) },
                    }"
                >
                  {{ aylikRaporReturn[item].toplamKonaklama }}
                </router-link>
              </td>
            </tr>
            <tr>
              <td
                  v-for="(item, index) in aylikRaporReturn.toplamGun == '28'
                    ? 28
                    : '' || aylikRaporReturn.toplamGun == '29'
                    ? 29
                    : '' || aylikRaporReturn.toplamGun == '30'
                    ? 30
                    : '' || aylikRaporReturn.toplamGun == '31'
                    ? 31
                    : '' || aylikRaporReturn.toplamGun == '32'
                    ? 32
                    : ''"
                  :key="index"
              >
                <p>%{{ aylikRaporReturn[item].dolulukOrani }}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <b-row class="justify-content-end">
          <div class="d-grid justify-content-end">
            <hr/>
            <h4>Rapor Özeti(Konaklama Sayısı)</h4>
            <h5 v-for="(tarife, index) in tarifeGet" :key="index">
              {{ aylikRaporReturn[tarife.tarifeAdi + 'Toplam'] }}
              {{ tarife.tarifeAdi }} Konaklama Kaydı Bulundu
            </h5>
            <h4>Toplam {{ toplamKisiGet }} Adet Konaklama Kaydı Bulundu</h4>
            <hr/>
          </div>
        </b-row>
        <b-row class="justify-content-end">
          <div class="d-grid justify-content-end">
            <h4>Rapor Özeti(Kişi Sayısı)</h4>
            <h5 v-for="(tarife, index) in tarifeGet" :key="index">
              {{ aylikRaporReturn.tekilKonukSayilari[tarife.tarifeID] }}
              {{ tarife.tarifeAdi }} Konaklama Kaydı Bulundu
            </h5>
            <h4>
              Toplam {{ toplamKonaklamaGet }} Adet Konaklama Kaydı Bulundu
            </h4>
            <hr/>
          </div>
        </b-row>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      spinner: true,
      years: [2019, 2020, 2021, 2022, 2023, 2024, 2025],
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      year: '',
      month: '',
      filtre: false,
    }
  },
  watch: {
    year: function (newVal, oldVal) {
      this.$store.dispatch('aylikRapor', {
        year: newVal,
        month: this.month,
      })
    },
    month: function (newVal, oldVal) {
      this.$store.dispatch('aylikRapor', {
        year: this.year,
        month: newVal,
      })
    },
  },
  methods: {
    goRaporGun(day, tarife) {
      const ay = this.monthControl()
      return this.year + '-' + ay + '-' + day
    },
    monthControl() {
      switch (this.month) {
        case 'Ocak':
          return '01'
          break
        case 'Şubat':
          return '02'
          break
        case 'Mart':
          return '03'
          break
        case 'Nisan':
          return '04'
          break
        case 'Mayıs':
          return '05'
          break
        case 'Haziran':
          return '06'
          break
        case 'Temmuz':
          return '07'
          break
        case 'Ağustos':
          return '08'
          break
        case 'Eylül':
          return '09'
          break
        case 'Ekim':
          return '10'
          break
        case 'Kasım':
          return '11'
          break
        case 'Aralık':
          return '12'
          break

        default:
          break
      }
    },
    monthControlReverse(ay) {
      switch (ay) {
        case '01':
          return 'Ocak'
          break
        case '02':
          return 'Şubat'
          break
        case '03':
          return 'Mart'
          break
        case '04':
          return 'Nisan'
          break
        case '05':
          return 'Mayıs'
          break
        case '06':
          return 'Haziran'
          break
        case '07':
          return 'Temmuz'
          break
        case '08':
          return 'Ağustos'
          break
        case '09':
          return 'Eylül'
          break
        case '10':
          return 'Ekim'
          break
        case '11':
          return 'Kasım'
          break
        case '12':
          return 'Aralık'
          break

        default:
          break
      }
    },
  },
  computed: {
    toplamKisiGet() {
      if (this.aylikRaporReturn) {
        Object.values(this.tarifeGet)
            .forEach((element) => {
              let name = this.aylikRaporReturn[element.tarifeAdi + 'Toplam']
              if (name) {
              }
            })
      }
    },
    toplamKonaklamaGet() {
      if (this.aylikRaporReturn.tekilKonukSayilari) {
        return this.aylikRaporReturn.tekilKonukSayilari.toplam
      }
    },
    aylikRaporReturn() {
      return this.$store.getters.aylikRaporReturn
    },
    tarifeGet() {
      return Object.values(this.$store.getters.tarife)
    },
    gunSayisi() {
      return parseInt(this.$store.state.raporlar.aylikRapor.toplamGun)
    },
  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.commit('clearAylikRapor')
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    let today = new Date()
    let month = today.getMonth() + 1
    month = month < 10 ? '0' + month : month
    this.year = today.getFullYear();
    (this.month =
        month == '01'
            ? 'Ocak'
            : '' || month == '02'
                ? 'Şubat'
                : '' || month == '03'
                    ? 'Mart'
                    : '' || month == '04'
                        ? 'Nisan'
                        : '' || month == '05'
                            ? 'Mayıs'
                            : '' || month == '06'
                                ? 'Haziran'
                                : '' || month == '07'
                                    ? 'Temmuz'
                                    : '' || month == '08'
                                        ? 'Ağustos'
                                        : '' || month == '09'
                                            ? 'Eylül'
                                            : '' || month == '10'
                                                ? 'Ekim'
                                                : '' || month == '11'
                                                    ? 'Kasım'
                                                    : '' || month == '12'
                                                        ? 'Aralık'
                                                        : ''),
        (this.filtre = true)
    this.$store
        .dispatch('aylikRapor', {
          year: this.year,
          month: this.month,
        })
        .then((res, position = 'bottom-right') => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  directives: {
    Ripple,
  },
}
</script>
<style scoped>
.v-select {
  display: inline-block !important;
  width: 400px;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

table {
  border: 1px solid grey;
}

.table th,
.table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

a {
  text-decoration: blink !important;
}

.v-select {
  width: 20%;
}

@media print {
  table {
    border: 1px solid grey !important;
  }

  th,
  td {
    text-align: start !important;
  }

  .unPrint {
    display: none !important;
  }
}

th,
td {
  text-align: start !important;
}
.spinners {
  position: absolute !important;
  margin-left: 47% !important;
  margin-top: 15% !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 900 !important;
}
</style>
<style>
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
